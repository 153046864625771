<template>
  <div class="weaper">
    <div class="banner">
      <div class="ban-title">社会责任</div>
      <div class="ban-en">SOCIAL RESPONSIBILITY</div>
      <div class="roueter-msg">首页>社会责任</div>
    </div>
    <div class="nav">
      <div
        class="nav-btn"
        v-for="item in navList"
        :class="item.id === index ? 'btn-active' : ''"
        :key="item.id"
        @click="changeNav(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import publicWelfare from '@/views/socialResponsibility/publicWelfare'
import helpAgriculture from '@/views/socialResponsibility/helpAgriculture'

export default {
  name: 'index',
  components: {
    publicWelfare,
    helpAgriculture
  },
  data() {
    return {
      index: 0,
      navList: [
        {
          id: 0,
          name: '社会公益',
          url: '/socialResponsibility/publicWelfare'
        },
        {
          id: 1,
          name: '爱心助农',
          url: '/socialResponsibility/helpAgriculture'
        }
      ]
    }
  },
  watch: {
    $route() {
      this.index = this.navList.findIndex((item)=> item.url ===this.$route.path)
    }
  },
  mounted() {
    this.index = this.navList.findIndex((item)=> item.url ===this.$route.path)
  },
  methods: {
    changeNav(item) {
      this.index = item.id
      this.$router.push(item.url)
    },
  }
}
</script>

<style lang="scss" scoped>
.weaper {
  .banner {
    width: 100%;
    height: 500px;
    background: url('https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/socialResponsibility/socialResponsibility.png')
      no-repeat;
    background-size: 100% 100%;
    padding-left: 360px;
    padding-top: 244px;
    .ban-title {
      font-size: 44px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 66px;
    }
    .ban-en {
      font-size: 24px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
    }
    .roueter-msg {
      margin-top: 96px;
      height: 24px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
    }
  }

  .nav {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fafafa;

    .nav-btn {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #434343;
      line-height: 24px;
      padding: 18px 48px;
      margin: 0 20px;
      cursor: pointer;
      white-space: nowrap;
    }
  }

  .btn-active {
    color: #c5a074 !important;
    border-bottom: 3px solid #c5a074;
  }
}
</style>
