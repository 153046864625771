<template>
  <div class="dig-weaper">
    <div class="pro-div">
      <div class="dig-div">
        <div style="padding: 161px 72px;">
          <div class="dig-title">数字加工</div>
          <div class="dig-line"></div>
          <div class="dig-content">将食品加工企业的原料采购、产品销售、仓储管理，生产管理，成本管理等核心业务协同一体，为企业打造数字化集中管理平台。</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "digitalProcessing"
}
</script>

<style lang="scss" scoped>
.dig-weaper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 108px 0;

  .pro-div {
    width: 1200px;
    height: 480px;
    background: url("https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/digital/digitalProcessing.png") no-repeat;
    background-size: 100% 100%;

    .dig-div {
      width: 600px;
      height: 480px;
      background: linear-gradient(90deg, #1B3F5B 0%, rgba(27, 63, 91, 0) 100%);

      .dig-title {
        font-size: 36px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 54px;
        padding-bottom: 24px;
      }

      .dig-line {
        width: 180px;
        height: 2px;
        background: #FFFFFF;
      }

      .dig-content {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        padding-top: 24px;
      }
    }
  }
}
</style>
