<template>
  <div class="weaper">
    <div class="banner">
      <div class="ban-title">央联云服务</div>
      <div class="ban-en">CLOUD SERVICE</div>
      <div class="roueter-msg">首页>央联云服务</div>
    </div>
    <div class="nav">
      <div class="nav-btn"
           v-for="item in navList"
           :class="item.id===index?'btn-active':''"
           :key="item.id"
           @click="changeNav(item.id)"
      >{{ item.name }}
      </div>
    </div>
    <div class="content">
      <div class="cloud-weaper">
        <div class="cloud-left">
          <div class="left-title">{{ titleEn }}</div>
          <div class="left-zh">{{ title }}</div>
          <div class="left-line"></div>
          <div class="left-content" v-html="content"></div>
        </div>
        <div class="cloud-right">
          <div id="div1">
            <img src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/cloudService/cloud2.png"
                 class="img1" alt="">
            <img src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/cloudService/cloud1.png"
                 class="img2" alt="">
            <img src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/cloudService/cloud5.png"
                 class="img3" alt="">
            <img src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/cloudService/cloud4.png"
                 class="img4" alt="">
            <img src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/cloudService/cloud3.png"
                 class="img5" alt="">
            <div class="center-weaper">
              <div class="center-middle">
                <div class="center-title">央联云服务</div>
                <div class="title-en">CLOUD SERVICE</div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import $ from 'jquery'

export default {
  name: "index",
  components: {},
  data() {
    return {
      index: 1,
      navList: [
        {
          id: 1,
          name: '大数据服务',
          en: 'BIG DATA',
          content: '没有网络安全 就没有国家安全，支撑机构或个人对海量、异构、快速变化数据采集、传输、存储、处理（包括计算、分析、可视化等）、交换、销毁等覆盖数据生命周期相关活动的各种数据服务。'
        },
        {
          id: 2,
          name: '云仓',
          en: 'STORAGE',
          content: '国家-战略应急储备可视化<br/>' +
              '企业-预定式，订单式<br/>' +
              '消费者-一站式全品类保鲜供给能力'
        },
        {
          id: 3,
          name: '云物流',
          en: 'LOGISTICS',
          content: '提高城市运力高效性确保供、需、侧架构性提升<br/>' +
              '国家-战略应急，物流资源可视、可控<br/>' +
              '企业-资源共享，资源资产效益最大化<br/>' +
              '消费者-满足商务部“15分钟生活圈打造”'
        },
        {
          id: 4,
          name: '云商务',
          en: 'BUSINESS',
          content: '数字洽谈<br/>' +
              '数字交易<br/>' +
              '数字合同<br/>' +
              '数字金融'
        },
        {
          id: 5,
          name: '央联云',
          en: 'CLOUD',
          content: '提供安全、可靠的计算和数据处理能力，让计算和人工智能成为普惠科技'
        }
      ],
      title: '大数据服务',
      titleEn: 'BIG DATA',
      content: '没有网络安全 就没有国家安全，支撑机构或个人对海量、异构、快速变化数据采集、传输、存储、处理（包括计算、分析、可视化等）、交换、销毁等覆盖数据生命周期相关活动的各种数据服务。',
      centerx: 225,
      centery: 225,
      r: 225,
      da: 72,
      a0: 50,
      timer: null
    }
  },
  watch: {
    $route() {
      if (this.$route.name === 'publicWelfare') {
        this.changeNav(1);
      }
      if (this.$route.name === 'cloudWarehouse') {
        this.changeNav(2);
      }
      if (this.$route.name === 'cloudLogistics') {
        this.changeNav(3);
      }
      if (this.$route.name === 'cloudBusiness') {
        this.changeNav(4);
      }
      if (this.$route.name === 'centralUnionCloud') {
        this.changeNav(5);
      }

      // console.log(this.$route, '124567890')
      // this.userId= this.$route.query.id
    }
  },

  mounted() {
    if (this.$route.name === 'publicWelfare') {
      this.changeNav(1);
    }
    if (this.$route.name === 'cloudWarehouse') {
      this.changeNav(2);
    }
    if (this.$route.name === 'cloudLogistics') {
      this.changeNav(3);
    }
    if (this.$route.name === 'cloudBusiness') {
      this.changeNav(4);
    }
    if (this.$route.name === 'centralUnionCloud') {
      this.changeNav(5);
    }
    this.posimgs();
  },
  methods: {
    changeNav(i) {
      this.index = i;
      // console.log(i)
      // this.a0 += 72;
      $('.left-content').removeClass('active');
      $('.left-zh').removeClass('active');
      $('.left-line').removeClass('active');
      $('.left-title').removeClass('active1');
      setTimeout(() => {
        switch (i) {
          case 1:
            this.a0 = 50;
            this.title = this.navList[i - 1].name;
            this.titleEn = this.navList[i - 1].en;
            this.content = this.navList[i - 1].content;
            $('.left-content').addClass('active');
            $('.left-zh').addClass('active');
            $('.left-line').addClass('active');
            $('.left-title').addClass('active1');
            break;
          case 2:
            this.a0 = 122;
            this.title = this.navList[i - 1].name;
            this.titleEn = this.navList[i - 1].en;
            this.content = this.navList[i - 1].content;
            $('.left-content').addClass('active');
            $('.left-zh').addClass('active');
            $('.left-line').addClass('active');
            $('.left-title').addClass('active1');
            break;
          case 3:
            this.a0 = 194;
            this.title = this.navList[i - 1].name;
            this.titleEn = this.navList[i - 1].en;
            this.content = this.navList[i - 1].content;
            $('.left-content').addClass('active');
            $('.left-zh').addClass('active');
            $('.left-line').addClass('active');
            $('.left-title').addClass('active1');
            break;
          case 4:
            this.a0 = 266;
            this.title = this.navList[i - 1].name;
            this.titleEn = this.navList[i - 1].en;
            this.content = this.navList[i - 1].content;
            $('.left-content').addClass('active');
            $('.left-zh').addClass('active');
            $('.left-line').addClass('active');
            $('.left-title').addClass('active1');
            break;
          case 5:
            this.a0 = 338;
            this.title = this.navList[i - 1].name;
            this.titleEn = this.navList[i - 1].en;
            this.content = this.navList[i - 1].content;
            $('.left-content').addClass('active');
            $('.left-zh').addClass('active');
            $('.left-line').addClass('active');
            $('.left-title').addClass('active1');
            break;
          default:
            break
        }
        this.posimgs();
      }, 100)
    },

    posimgs() {
      // console.log(this.a0);
      for (let i = 0; i < 5; i++) {
        // $(".img")[i].style.left = this.centerx + this.r * Math.cos((this.da * i + this.a0) / 180 * Math.PI) + "px";
        // $(".img")[i].style.top = this.centery + this.r * Math.sin((this.da * i + this.a0) / 180 * Math.PI) + "px";
        let x = this.centerx + this.r * Math.cos((this.da * i + this.a0) / 180 * Math.PI);
        let y = this.centery + this.r * Math.sin((this.da * i + this.a0) / 180 * Math.PI);
        let str = '.img' + (i + 1);
        let xStr = x + 'px';
        let yStr = y + 'px';
        $(str).animate({left: xStr, top: yStr});
      }
    }
  },
  beforeDestroy() {
  }
}
</script>

<style lang="scss" scoped>
.weaper {
  .banner {
    width: 100%;
    height: 500px;
    background: url("https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/cloudService/cloudService.png") no-repeat;
    background-size: 100% 100%;
    padding-left: 360px;
    padding-top: 244px;

    .ban-title {
      font-size: 44px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 66px;
    }

    .ban-en {
      font-size: 24px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 36px;
    }

    .roueter-msg {
      margin-top: 96px;
      height: 24px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
    }
  }

  .nav {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FAFAFA;

    .nav-btn {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #434343;
      line-height: 24px;
      padding: 18px 48px;
      margin: 0 20px;
      cursor: pointer;
      white-space: nowrap;
    }
  }

  .btn-active {
    color: #C5A074 !important;
    border-bottom: 3px solid #C5A074;
  }

  .content {
    width: 100%;
    height: 720px;
    background: url("https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/cloudService/bigDataBg.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .cloud-weaper {
      width: 1050px;
      height: 550px;
      display: flex;

      .cloud-left {
        width: 480px;
        height: 550px;

        .left-title {
          font-size: 100px;
          font-family: Times-Regular, Times;
          font-weight: 400;
          color: #FAFAFA;
          line-height: 120px;
        }

        .left-zh {
          font-size: 36px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #1890FF;
          line-height: 54px;
          padding-bottom: 16px;
        }

        .left-line {
          width: 180px;
          height: 0px;
          opacity: 1;
          border: 2px solid #D9D9D9;
        }

        .left-content {
          padding-top: 16px;
          font-size: 20px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #434343;
          line-height: 30px;
        }
      }

      .active {
        -webkit-animation-name: fadeIn; /*动画名称*/
        -webkit-animation-duration: 1s; /*动画持续时间*/
        -webkit-animation-iteration-count: 1; /*动画次数*/
        -webkit-animation-delay: 0s; /*延迟时间*/
      }

      .active1 {
        -webkit-animation-name: fadeIn; /*动画名称*/
        -webkit-animation-duration: 1.5s; /*动画持续时间*/
        -webkit-animation-iteration-count: 1; /*动画次数*/
        -webkit-animation-delay: 0s; /*延迟时间*/
      }

      @-webkit-keyframes fadeIn {
        0% {
          opacity: 0; /*初始状态 透明度为0*/
        }
        50% {
          opacity: 0; /*中间状态 透明度为0*/
        }
        100% {
          opacity: 1; /*结尾状态 透明度为1*/
        }
      }

      .cloud-right {
        #div1 {
          position: relative;
          width: 550px;
          height: 550px;
          display: flex;
          align-items: center;
          justify-content: center;

          .img1, .img2, .img3, .img4, .img5 {
            position: absolute;
            width: 100px;
            height: 100px;
          }

          .center-weaper {
            width: 264px;
            height: 264px;
            background: linear-gradient(225deg, #40A9FF 0%, rgba(145, 212, 255, 0.47999998927116394) 100%);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            .center-middle {
              width: 240px;
              height: 240px;
              background: #1890FF;
              border-radius: 50%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              .center-title {
                font-size: 36px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 54px;
              }

              .title-en {
                font-size: 20px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 30px;
              }

            }
          }

        }
      }
    }
  }
}
</style>
