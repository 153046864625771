<template>
  <div class="au-weaper">
    <div class="five-system">
      <div class="titls">
        <h1>五大体系</h1>
        <h3>FIVE CERTIFICATION SYSTEMS</h3>
      </div>
      <div class="five-pto">
        <div class="ptos">
          <img class="withbig" :src="bg23Src" alt="" />
          <div class="mball"></div>
          <div class="mbballtext">
            全球首家发布冷链国际食品产业团体标准企业，与中国标准化研究院深度合作，共同制定并发布食品安全标准
          </div>
          <div class="titlet">
            <div><img :src="bzSrc" alt="" /></div>
            <h1>标准体系</h1>
            <h3>STANDARD SYSTEM</h3>
          </div>
        </div>
        <div class="ptos" style="margin: 0 24px">
          <img class="withbig" :src="bg24Src" alt="" />
          <div class="mball"></div>
          <div class="mbballtext">
            制定食品安全标准，搭建食品安全屏障，打造食品安全品牌城市，实现食品安全“四个最严”，确保不发生系统性、区域性食品安全风险。实现全球冷链食品的检验、检测、认证、溯源、原产地评定、境外预检验、食品安全风险评估、食品标签咨询、有害生物管理、环境监测及评价、管理体系认证等服务
          </div>
          <div class="titlet">
            <div><img :src="zlSrc" alt="" /></div>
            <h1>质量体系</h1>
            <h3>QUALITY SYSTEM</h3>
          </div>
        </div>
        <div class="ptos">
          <img class="withbig" :src="bg25Src" alt="" />
          <div class="mball"></div>
          <div class="mbballtext">
            为政府提供决策依据，消除汇率波动对国内农副产品流通产业的影响，提前预警，指导种植养殖，满足中国市场消费者的需求，推动世界经济发展
          </div>
          <div class="titlet">
            <div><img :src="jgSrc" alt="" /></div>
            <h1>价格体系</h1>
            <h3>PRICE SYSTEM</h3>
          </div>
        </div>
        <div class="ptos" style="margin: 0 24px">
          <img class="withbig" :src="bg26Src" alt="" />
          <div class="mball"></div>
          <div class="mbballtext">
            品牌管理中心，品牌发布中心，品牌大数据中心，品牌价值评估中心，品牌研究中心，品牌培训中心提供产品身份证及品牌身份证。充分发挥食品企业品牌效应，为企业赋能增值，助力中国产业品牌化，品牌产业国际化
          </div>
          <div class="titlet">
            <div><img :src="ppSrc" alt="" /></div>
            <h1>品牌体系</h1>
            <h3>BRAND SYSTEM</h3>
          </div>
        </div>
        <div class="ptos">
          <img class="withbig" :src="bg27Src" alt="" />
          <div class="mball"></div>
          <div class="mbballtext">
            搭建线上线下深度融合的O2O交易平台，为国内外贸易商、进口商提供价格发布、产品展示、交易结算服务，实现现货、期货的线上线下交易、交割、拍卖
          </div>
          <div class="titlet">
            <div><img :src="jySrc" alt="" /></div>
            <h1>交易体系</h1>
            <h3>TRADING SYSTEM</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'authentication',
  data() {
    return {
      bg23Src:
          'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(23).png',
      bg24Src:
          'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(24).png',
      bg25Src:
          'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(25).png',
      bg26Src:
          'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(26).png',
      bg27Src:
          'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/bg(27).png',
      bzSrc:
          'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/icon_tbtx.png',
      zlSrc:
          'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/icon_zltx.png',
      jgSrc:
          'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/icon_jytx.png',
      ppSrc:
          'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/icon_pptx.png',
      jySrc:
          'https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/home/icon_jytx.png',
    }
  }
}
</script>

<style lang="scss" scoped>
.au-weaper {
  padding-bottom: 108px;

  // .five-system {
  //   .titls {
  //     text-align: center;
  //     margin-top: 80px;
  //     margin-bottom: 40px;

  //     h1 {
  //       height: 54px;
  //       font-size: 36px;
  //       font-family: PingFang SC-Medium, PingFang SC;
  //       font-weight: 500;
  //       color: #262626;
  //       line-height: 54px;
  //     }

  //     h3 {
  //       height: 24px;
  //       font-size: 20px;
  //       font-family: Noto Serif-Regular, Noto Serif;
  //       font-weight: 400;
  //       color: #bfbfbf;
  //       line-height: 24px;
  //     }
  //   }

  //   .five-pto {
  //     display: flex;
  //     justify-content: center;

  //     .ptos {
  //       height: 402px;
  //       width: 221px;
  //       transition: all 1s;
  //       position: relative;
  //       overflow: hidden;

  //       .withbig {
  //         transition: all 1s;
  //         width: 221px;
  //         height: 402px;
  //       }

  //       .titlet {
  //         bottom: 30px;
  //         left: 24px;
  //         position: absolute;

  //         img {
  //           width: 60px;
  //           height: 60px;
  //         }

  //         h1 {
  //           height: 42px;
  //           font-size: 28px;
  //           font-family: PingFang SC-Medium, PingFang SC;
  //           font-weight: 500;
  //           color: #ffffff;
  //           line-height: 42px;
  //         }

  //         h3 {
  //           height: 24px;
  //           font-size: 16px;
  //           font-family: PingFang SC-Regular, PingFang SC;
  //           font-weight: 400;
  //           color: #ffffff;
  //           line-height: 24px;
  //         }
  //       }

  //       .mbballtext {
  //         padding: 24px;
  //         width: 212px;
  //         height: 0px;
  //         transition: all 1s;
  //         color: #fff;
  //         position: absolute;
  //         bottom: -20px;
  //         z-index: 9999;
  //       }

  //       .mball {
  //         width: 212px;
  //         height: 0px;
  //         transition: all 1s;
  //         z-index: 999;
  //         position: absolute;
  //         bottom: 0;
  //         background: rgba(0, 0, 0, 0.7) center center;
  //         background-blend-mode: multiply;
  //         filter: blur(7px);
  //         overflow: hidden;
  //       }
  //     }

  //     .ptos:hover {
  //       cursor: pointer;
  //       width: 282px;

  //       .withbig {
  //         width: 282px;
  //         height: 402px;
  //       }

  //       .mball {
  //         width: 282px;
  //         height: 402px;
  //       }

  //       .mbballtext {
  //         width: 282px;
  //         height: 422px;
  //       }
  //     }
  //   }
  // }
  .five-system {
    .titls {
      text-align: center;
      margin-top: 80px;
      margin-bottom: 40px;
    }
          h1 {
        height: 54px;
        font-size: 36px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #262626;
        line-height: 54px;
      }

      h3 {
        height: 24px;
        font-size: 20px;
        font-family: Noto Serif-Regular, Noto Serif;
        font-weight: 400;
        color: #bfbfbf;
        line-height: 24px;
      }


    .five-pto {
      display: flex;
      justify-content: center;
      .ptos:hover {
        cursor: pointer;
        width: 282px;
        .withbig {
          width: 282px;
          height: 402px;
        }
        .mball {
          width: 282px;
          height: 402px;
        }
        .mbballtext {
          width: 282px;
          height: 422px;
        }
      }
      .ptos {
        height: 402px;
        width: 221px;
        transition: all 2s;
        position: relative;
        overflow: hidden;
        .withbig {
          transition: all 2s;
          width: 221px;
          height: 402px;
        }
        .titlet {
          bottom: 30px;
          left: 24px;
          position: absolute;
          img {
            width: 60px;
            height: 60px;
          }
          h1 {
            height: 42px;
            font-size: 28px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 42px;
          }
          h3 {
            height: 24px;
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 24px;
          }
        }
        .mbballtext {
          padding: 24px;
          width: 212px;
          height: 0px;
          transition: all 2s;
          color: #000;
          position: absolute;
          bottom: -20px;
          z-index: 9999;
        }
        .mball {
          width: 212px;
          height: 0px;
          transition: all 1s;
          z-index: 999;
          position: absolute;
          bottom: 0;
          background:  hsla(0,0%,100%,.6);
          backdrop-filter: blur(5px);
        }
      }
    }
  }
}
</style>
