<template>

</template>

<script>
export default {
name: "CentralUnionCloud"
}
</script>

<style scoped>

</style>
