<template>
  <div>
    <div class="hel-weaper">
      <a class="agr-div" v-for="item in hellist" @click.prevent="goMsg(item.id)" :href="'https://www.wonmore.com/wordMsg/information?id='+item.id">
        <img :src="item.thumbnail" width="384" height="200" alt="" />
        <div class="agr-title">{{ item.title }}</div>
        <div class="agr-bottom">
          <div class="agr-date">{{ item.created_at }}</div>
          <div>
            <el-icon>
              <view />
            </el-icon>
            阅读{{ item.views }}
          </div>
        </div>
      </a>
    </div>
    <el-pagination
      class="hel-page"
      background
      layout="prev, pager, next"
      :page-size="pageSize"
      :total="total"
      @current-change="pageChage"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getNewsList } from '@/api/api.js'
import $ from 'jquery'
export default {
  name: 'helpFarmers',

  data() {
    return {
      hellist: [],
      pageSize: 9,
      total: 0,
      page: 1
    }
  },
  mounted() {
    this.getNewsList(2)
  },
  methods: {
    pageChage(val) {
      // console.log(val);
      this.page = val
      this.getNewsList(2)
    },
    goMsg(id) {
      this.$router.push({
        name: 'information',
        query: { id: id }
      })
    },
    getNewsList(id) {
      let data = new FormData()
      data.append('cat_id', id)
      data.append('page', this.page)
      data.append('page_size', this.pageSize)
      // let data = { cat_id: id, page: 1, page_size: 9 }
      /*getNewsList(data).then((res) => {
        console.log(res)
        if (res.status == 'success') {
          this.hellist = res.data.data
          this.total = res.data.total
          console.log()
        }
      })*/
      let that = this
      $.ajax({
        url: 'https://outcommon.wonmore.com/trade/news_list',
        dataType: 'json',
        type: 'post',
        async: false,
        data: data,
        processData: false, // 使数据不做处理
        contentType: false, // 不要设置Content-Type请求头
        success: function (res) {
          if (res.status === 'success') {
            that.hellist = res.data.data
            that.total = res.data.total
          } else {
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.hel-weaper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 108px 0;
  flex-wrap: wrap;
  width: 1192px;
  margin: 0 auto;

  .agr-div:hover {
    cursor: pointer;
    width: 384px;
    height: 286px;
    border-radius: 4px 4px 4px 4px;
    margin-bottom: 32px;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05000000074505806),
      0px 6px 16px 1px rgba(0, 0, 0, 0.07999999821186066),
      0px 3px 6px -4px rgba(0, 0, 0, 0.11999999731779099);
  }
  .agr-div {
    width: 384px;
    height: 286px;
    // box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05000000074505806), 0px 6px 16px 1px rgba(0, 0, 0, 0.07999999821186066), 0px 3px 6px -4px rgba(0, 0, 0, 0.11999999731779099);
    border-radius: 4px 4px 4px 4px;
    margin-bottom: 32px;

    .agr-title {
      width: 100%;
      padding: 15px 16px 8px 16px;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #434343;
      line-height: 24px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .agr-bottom {
      width: 100%;
      padding: 0 16px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #bfbfbf;
      line-height: 22px;
      display: flex;
      justify-content: space-between;
    }
  }

  // .hel-page {

  // }
}
</style>
<style scoped>
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #c5a074;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #c5a074;
}
/deep/ .el-pagination {
  width: 500px !important;
  margin: 0 auto 20px;
  text-align: center;
}
</style>
