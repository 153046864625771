<template>
  <div class="weaper">
    <div class="banner">
      <div class="ban-title">关于我们</div>
      <div class="ban-en">ABOUT US</div>
      <div class="roueter-msg">首页>关于我们</div>
    </div>
    <div class="nav">
      <div
          class="nav-btn"
          v-for="item in navList"
          :class="item.id === index ? 'btn-active' : ''"
          :key="item.id"
          @click="changeNav(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

import $ from 'jquery'

export default {
  name: 'index',
  components: {},
  data() {
    return {
      index: 0,
      navList: [
        {
          id: 0,
          name: '集团介绍',
          url: '/aboutUs/company'
        },
        {
          id: 1,
          name: '平台介绍',
          url: '/aboutUs/platform'
        },
        {
          id: 2,
          name: '港口经济三大中心',
          url: '/aboutUs/serviceCenter'
        },
        {
          id: 3,
          name: '五大体系',
          url: '/aboutUs/authentication'
        },
        {
          id: 4,
          name: '一站式服务',
          url: '/aboutUs/service'
        }
      ],
      eventList: []
    }
  },
  watch: {
    $route() {
      this.index = this.navList.findIndex((item)=> item.url ===this.$route.path)
    }
  },
  mounted() {
    this.index = this.navList.findIndex((item)=> item.url ===this.$route.path)
  },
  methods: {
    changeNav(item) {
      this.index = item.id
      this.$router.push(item.url)
    },

  }
}
</script>

<style lang="scss" scoped>
.weaper {
  .banner {
    width: 100%;
    height: 500px;
    background: url('https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/aboutUs/bg.png') no-repeat;
    background-size: 100% 100%;
    padding-left: 360px;
    padding-top: 244px;

    .ban-title {
      font-size: 44px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 66px;
    }

    .ban-en {
      font-size: 24px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
    }

    .roueter-msg {
      margin-top: 96px;
      height: 24px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
    }
  }

  .nav {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fafafa;

    .nav-btn {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #434343;
      line-height: 24px;
      padding: 18px 48px;
      margin: 0 20px;
      cursor: pointer;
      white-space: nowrap;
    }
  }

  .btn-active {
    color: #c5a074 !important;
    border-bottom: 3px solid #c5a074;
  }
}
</style>
