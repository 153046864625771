<template>
<div class="weaper-404">
  <img src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/404.png" width="300" alt="">
  <p>抱歉，您访问的页面不存在！</p>
  <div class="btn-div">
    <div class="back" @click="back">返回</div>
    <div class="index" @click="home">首页</div>
  </div>
</div>
</template>

<script>
import {useRouter} from "vue-router";
export default {
  name: "404",
  setup(){
    const $router = useRouter();
    const back=()=>{
      $router.go(-1);
    }
    const home=()=>{
      $router.push('/homePage');
    }
    return{
      back,
      home
    }
  }
}
</script>

<style lang="scss" scoped>
.weaper-404{
  width: 100%;
  height: calc(100% - 309px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #dedede;

  .btn-div{
    display: flex;
    padding-top: 30px;
    .back{
      padding: 10px 30px;
      background: #c5a375;
      color: #FFFFFF;
      border-radius: 5px;
      cursor: pointer;
    }
    .index{
      color: #c5a375;
      border: 1px solid #c5a375;
      border-radius: 5px;
      padding: 10px 30px;
      background: #FFFFFF;
      margin-left: 30px;
      cursor: pointer;
    }
  }
}
</style>
