<template>
  <div class="dig-weaper">
    <img src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/digital/digitalService.png"
         width="600" alt="">
    <div class="dig-div">
      <div style="padding: 161px 72px;">
        <div class="dig-title">数字服务</div>
        <div class="dig-line"></div>
        <div class="dig-content">基于业务中台+数据中台为核心技术,打造平台数字闭环,赋能数字资产运营,构建业务增长能力。</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "digitalService"
}
</script>

<style lang="scss" scoped>
.dig-weaper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 108px 0;

  .dig-div {
    width: 600px;
    height: 480px;
    background: #F5F5F5;

    .dig-title {
      font-size: 36px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #434343;
      line-height: 54px;
      padding-bottom: 24px;
    }

    .dig-line {
      width: 180px;
      height: 2px;
      background: #D9D9D9;
    }

    .dig-content {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #434343;
      line-height: 24px;
      padding-top: 24px;
    }
  }
}
</style>
