<template>
  <div class="weaper" id="digitalFoodPage">
    <!-- @mousewheel="rollScroll($event)" -->
    <div class="banner">
       <!-- v-if="!navFixed" -->
      <div class="ban-title">数字食品</div>
      <div class="ban-en">DIGITAL FOOD</div>
      <div class="roueter-msg">首页>数字食品</div>
    </div>
    <!-- <div class="banners" v-else></div> -->
    <div class="nav">
      <div class="container" :class="navFixed?'fixeds':''">
        <div
            class="nav-btn"
            v-for="item in navList"
            :class="item.id === index ? 'btn-active' : ''"
            :key="item.id"
            @click="changeNav(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="content">
      <!--      <digitalAgriculture v-if="index === 1"></digitalAgriculture>
            <digitalProcessing v-if="index === 2"></digitalProcessing>
            <digitalService v-if="index === 3"></digitalService>
            <productiveIntegration v-if="index === 4"></productiveIntegration>-->
      <div class="dig-weaper" id="digitalFood1">
        <img src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/digital/digitalAgriculture.png"
             width="600" alt="">
        <div class="dig-div">
          <div style="padding: 161px 72px;">
            <div class="dig-title">数字农业</div>
            <div class="dig-line"></div>
            <div class="dig-content">数字农业系统,依托物联网,云计算,大数据等先进技术,构建智慧化农业生态,实现农业精准化,高效化管理,让农业生产全程可控。</div>
          </div>
        </div>
      </div>
      <div class="dig-weaper" id="digitalFood2">
        <div class="pro-div">
          <div class="dig-div1">
            <div style="padding: 161px 72px;">
              <div class="dig-title1">数字加工</div>
              <div class="dig-line1"></div>
              <div class="dig-content1">将食品加工企业的原料采购、产品销售、仓储管理，生产管理，成本管理等核心业务协同一体，为企业打造数字化集中管理平台。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="dig-weaper" id="digitalFood3">
        <img src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/digital/digitalService.png"
             width="600" alt="">
        <div class="dig-div">
          <div style="padding: 161px 72px;">
            <div class="dig-title">数字服务</div>
            <div class="dig-line"></div>
            <div class="dig-content">基于业务中台+数据中台为核心技术,打造平台数字闭环,赋能数字资产运营,构建业务增长能力。</div>
          </div>
        </div>
      </div>
      <div class="dig-weaper" id="digitalFood4">
        <div class="pro-div1">
          <div class="dig-div2">
            <div style="padding: 161px 72px;">
              <div class="dig-title1">多产融合</div>
              <div class="dig-line1"></div>
              <div class="dig-content1">以新型消费需求为龙头，以多产融合发展为基础，以国内国际双循环为支撑。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import digitalAgriculture from '@/views/digitalFood/digitalAgriculture'
import digitalProcessing from '@/views/digitalFood/digitalProcessing'
import digitalService from '@/views/digitalFood/digitalService'
import productiveIntegration from '@/views/digitalFood/productiveIntegration'
import $ from 'jquery'

export default {
  name: 'index',
  components: {
    digitalAgriculture,
    digitalProcessing,
    digitalService,
    productiveIntegration
  },
  data() {
    return {
      index: 0,
      navList: [
        {
          id: 1,
          name: '数字农业'
        },
        {
          id: 2,
          name: '数字加工'
        },
        {
          id: 3,
          name: '数字服务'
        },
        {
          id: 4,
          name: '多产融合'
        }
      ],
      navFixed: false,
    }
  },
  watch: {
    $route() {
      if (this.$route.name === 'digitalAgriculture') {
        this.index = 1
      }
      if (this.$route.name === 'digitalProcessing') {
        this.index = 2
      }
      if (this.$route.name === 'digitalService') {
        this.index = 3
      }
      if (this.$route.name === 'productiveIntegration') {
        this.index = 4
      }
      this.scrollInto()
      // console.log(this.$route, '124567890')
      // this.userId= this.$route.query.id
    }
  },

  mounted() {
    this.scrollFnc()
    if (this.$route.name === 'digitalAgriculture') {
      this.index = 1
    }
    if (this.$route.name === 'digitalProcessing') {
      this.index = 2
    }
    if (this.$route.name === 'digitalService') {
      this.index = 3
    }
    if (this.$route.name === 'productiveIntegration') {
      this.index = 4
    }
    this.scrollInto()
  },
  methods: {
    changeNav(i) {
      this.index = i
      this.scrollInto()
    },
    scrollInto(){
      // let returnEle = document.getElementById(`digitalFood${this.index}`)
      // returnEle.scrollIntoView(true)
      let i = this.index
      let arr = [0, 0, 750, 1200, 1700]
      window.scrollTo(0, arr[i] || 0)
    },
    scrollFnc() {
      let that = this
      window.addEventListener('scroll', function (e) {
        let isDigitalFoodPage = window.location.href.indexOf("/digitalFood") > -1
        if (isDigitalFoodPage) {
          var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
          if (scrollTop >= (500 - 96)) {
            that.navFixed = true
          } else {
            that.navFixed = false
          }
          if (scrollTop < 700) {
            that.index = 1
          } else if (700 <= scrollTop && scrollTop < 1174) {
            that.index = 2;
          } else if (1174 <= scrollTop && scrollTop < 1648) {
            that.index = 3;
          } else if (scrollTop >= 1648) {
            that.index = 4;
          }
        }
      }, false);
    }
  }
}
</script>

<style lang="scss" scoped>
.weaper {
  .banner {
    width: 100%;
    height: 500px;
    background: url('https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/digital/ditigalBg.png') no-repeat;
    background-size: 100% 100%;
    padding-left: 360px;
    padding-top: 244px;

    .ban-title {
      font-size: 44px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 66px;
    }

    .ban-en {
      font-size: 24px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
    }

    .roueter-msg {
      margin-top: 96px;
      height: 24px;
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
    }
  }

  .banners {
    width: 100%;
    height: 170px;
  }

  .nav {
    width: 100%;
    height: 72px;
    background: #fafafa;
    .container{
      width: 100%;
      height: 72px;
      background: #fafafa;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .nav-btn {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #434343;
      line-height: 24px;
      padding: 18px 48px;
      margin: 0 20px;
      cursor: pointer;
      white-space: nowrap;
    }
  }

  .fixeds {
    position: fixed;
    top: 96px;
  }

  .btn-active {
    color: #c5a074 !important;
    border-bottom: 3px solid #c5a074;
  }

  .content {
    .dig-weaper {
      display: flex;
      align-items: center;
      justify-content: center;

      .pro-div {
        width: 1200px;
        height: 480px;
        background: url("https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/digital/digitalProcessing.png") no-repeat;
        background-size: 100% 100%;
      }

      .pro-div1 {
        width: 1200px;
        height: 480px;
        background: url("https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/digital/productiveIntegration.png") no-repeat;
        background-size: 100% 100%;
      }

      .dig-div {
        width: 600px;
        height: 480px;
        background: #F5F5F5;
      }

      .dig-div1 {
        width: 600px;
        height: 480px;
        background: linear-gradient(90deg, #1B3F5B 0%, rgba(27, 63, 91, 0) 100%);
      }

      .dig-div2 {
        width: 600px;
        height: 480px;
        background: linear-gradient(90deg, #A58661 0%, rgba(166, 135, 98, 0) 100%);
      }

      .dig-title {
        font-size: 36px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #434343;
        line-height: 54px;
        padding-bottom: 24px;
      }

      .dig-title1 {
        font-size: 36px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 54px;
        padding-bottom: 24px;
      }

      .dig-line {
        width: 180px;
        height: 2px;
        background: #D9D9D9;
      }

      .dig-line1 {
        width: 180px;
        height: 2px;
        background: #FFFFFF;
      }

      .dig-content {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #434343;
        line-height: 24px;
        padding-top: 24px;
      }

      .dig-content1 {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        padding-top: 24px;
      }

    }
  }
}
</style>
