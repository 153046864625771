<template>
  <div class="pla-weaper">
    <div class="bg-weaper">
      <div class="pla-bg">
        <div class="pla-title">平台介绍</div>
        <div class="pla-en">PLATFORM INTRODUCTION</div>
        <div class="pla-content">
          巴龙·央联万贸集团创新食品产业互联网双循环共享平台，打造国内外双循环示范项目，是产业互联网平台经济领军者。集团历经8年精心筹划设计，搭建“三+三+四+五”系统：即建设国际多级冷链食品海关查验通关中心、中央保税食品加工枢纽中心、国际冷链食品交易中心“三大中心”，构建食品产业、消费、贸易“三大平台”，互联网、供应链、数字金融、品牌集群“四大服务系统”，标准、质量、价格、品牌、交易“五大体系”，打造集一二三产融合+产业互联网+数字化金融+品牌集群+政策环境于一体的食品产业互联网双循环5.0共享平台，实现政府、平台、企业形成合力的新型经济发展格局。推动构建完整的内需体系，打通生产、分配、流通、消费各个环节，形成以国内大循环为主体、国内国际双循环相互促进的新发展格局，实现绿色高质量发展。
        </div>
        <div class="pla-bottom">
          <div class="pla-icon">
            <img src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/aboutUs/threeCenter.png"
                 width="48" alt="">
            三大中心
          </div>
          <div class="pla-line"></div>
          <div class="pla-icon">
            <img src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/aboutUs/threePlatform.png"
                 width="48" alt="">
            三大平台
          </div>
          <div class="pla-line"></div>
          <div class="pla-icon">
            <img src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/aboutUs/fourService.png"
                 width="48" alt="">
            四大服务系统
          </div>
          <div class="pla-line"></div>
          <div class="pla-icon">
            <img src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/aboutUs/fiveEcology.png"
                 width="48" alt="">
            五大生态体系
          </div>
        </div>
      </div>
    </div>
    <div class="big-event">
      <div style="display: flex;">
        <div class="pla-big-event">平台大事记</div>
        <div style="display: flex;">
          <div class="en-line"></div>
          <div class="pla-big-en">DEVELOPMENT PATH</div>
        </div>
      </div>
      <div class="middle-year">
        <div class="pla-prve" @click="prveBtn"></div>
        <div class="year-weaper">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in eventList" :key="index">
                <div class="swiper-slide-content">
                  <div class="content">
                    <span class="ev-line" :style="{background: indexs===index?'#c5a074':'#bfbfbf'}"></span>
                    <span class="ev-year" :style="{color: indexs===index?'#262626':'#bfbfbf'}">{{ item.year }}</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="pla-next" @click="nextBtn"></div>
      </div>

      <div style="width: 689px;">
        <el-carousel
            height="430px"
            :autoplay="false"
            direction="vertical"
            indicator-position="none"
            arrow="always"
            ref="banner"
            @change="carChange"
            :loop="false"
        >
          <el-carousel-item v-for="(item,index) in eventList" :key="index">
            <div class="year-bold"><span class="year-line"></span>{{ item.year }}</div>
            <div class="event-detail">{{ item.event.content }}</div>
            <div class="imgBox" v-if="item.event.files.length">
              <img style="width:320px;height:162px" :src="val" alt="" v-for="(val, i) in item.event.files" :key="i">
            </div>
          </el-carousel-item>
        </el-carousel>

      </div>
    </div>
  </div>
</template>

<script>
import {bigEvents} from '@/api/api.js'
import $ from 'jquery'

export default {
  name: "platform",
  data() {
    return {
      eventList: [],
      indexs: 0
    }
  },
  mounted() {
    setTimeout(()=>{
      this.myswiper()
    })
    this.getBigEvent()
    if(this.$route.query.isTop){
      window.scrollTo(0,500)
    }
  },
  methods: {
    myswiper: function () {
      var timelineSwiper = new Swiper('.year-weaper .swiper-container', {
        direction: 'vertical',
        loop: false,
        speed: 1600,
        mousewheelControl: false,
        slidesPerView :'auto',
        centeredSlides : true,
        pagination: 'null',
        slidesOffsetBefore : 105,
        paginationBulletRender: function (swiper, index, className) {
          // var year = document.querySelectorAll('.swiper-slide')[index].getAttribute('data-year');
          // return '<span class="' + className + '">' + year + '</span>';
        },
        paginationClickable: true,
        scrollbarHide: false,
        scrollbarDraggable: true,
        scrollbarSnapOnRelease: true,
        nextButton: '.pla-next',
        prevButton: '.pla-prve',
      });
    },
    getBigEvent() {
      /*bigEvents({}).then((res) => {
        console.log(res)
        if (res.status === 'success') {
          let _data = res.data;
          let arr = []
          for (let key in _data) {
            if (_data[key].length > 1) {
              for (let i = 0; i < _data[key].length; i++) {
                arr.push({
                  year: key,
                  event: _data[key][i]
                })
              }
            } else {
              arr.push({
                year: key,
                event: _data[key][0]
              })
            }

          }
          console.log(arr);
          this.eventList = arr;

        } else {

        }
      })*/
      let that = this;
      $.ajax({
        url: 'https://outcommon.wonmore.com/trade/chronicle_events',
        dataType: 'json',
        type: 'get',
        async: false,
        data: {},
        processData: false, // 使数据不做处理
        contentType: false, // 不要设置Content-Type请求头
        success: function (res) {
          // console.log(res)
          if (res.status === 'success') {
            let _data = res.data;
            let arr = []
            for (let key in _data) {
              if (_data[key].length > 1) {
                for (let i = 0; i < _data[key].length; i++) {
                  arr.push({
                    year: key,
                    event: _data[key][i]
                  })
                }
              } else {
                arr.push({
                  year: key,
                  event: _data[key][0]
                })
              }

            }
            // console.log(arr);
            that.eventList = arr;
          } else {

          }
        }
      })
    },

    prveBtn() {
      this.$refs.banner.prev();
      // this.$refs.banner1.prev();
    },
    nextBtn() {
      this.$refs.banner.next();
      // this.$refs.banner1.next();
    },
    carChange(r) {
      this.indexs = r;
    }
  }
}
</script>

<style lang="scss" scoped>
.pla-weaper {
  padding: 108px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .bg-weaper {
    background: #111f5a;

    .pla-bg {
      width: 1200px;
      height: 432px;
      background: url("https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/aboutUs/platformBg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;

      .pla-title {
        font-size: 36px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 72px;
        padding-bottom: 20px;
        margin-top: -50px;
      }

      .pla-en {
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #FAFAFA;
        line-height: 1px;
        margin-bottom: 30px;
        border-left: 64px solid #fff;
        border-right: 64px solid #fff;
        padding: 0 8px;
        text-align: center;
      }

      .pla-content {
        width: 963px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        text-align: justify;
      }

      .pla-bottom {
        width: 900px;
        height: 114px;
        background: #FAFAFA;
        box-shadow: 0px 9px 28px 8px rgba(24, 39, 116, 0.05000000074505806), 0px 6px 16px 1px rgba(24, 39, 116, 0.05000000074505806), 0px 3px 6px -4px rgba(24, 39, 116, 0.05000000074505806);
        border-radius: 8px 8px 8px 8px;
        position: absolute;
        bottom: -57px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 70px;

        .pla-line {
          width: 2px;
          height: 30px;
          opacity: 1;
          border: 1px solid #D9D9D9;
        }

        .pla-icon {
          font-size: 20px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #434343;
          line-height: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .big-event {
    width: 100%;
    height: 710px;
    background: url("https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/aboutUs/bigEvent.png") no-repeat;
    background-size: 100% 100%;
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    .pla-big-event {
      font-size: 48px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #262626;
      line-height: 48px;
      width: 47px;
    }

    .en-line {
      width: 2px;
      height: 56px;
      background: #c5a074;
      margin-left: 20px;
    }

    .pla-big-en {
      padding-left: 55px;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #C5A074;
      line-height: 24px;
      transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      margin-left: -216px;
    }

    .middle-year {
      width: 206px;
      height: 430px;
      border-left: 2px dashed #e0e0e0;
      border-right: 2px dashed #e0e0e0;
      //border-image: linear-gradient(#ededed, #e0e0e0,#ededed) 2 2;
      margin: 0 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .swiper-container {
        height: 270px;
        overflow: hidden;
        position: relative;

        .num-position {
          width: 20px;
          height: 149px;
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          right: 66px;
          top: 166px;
          z-index: 2;
        }
      }

      .swiper-wrapper {
        transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
        flex-direction: column;
        justify-content: center;
      }

      .swiper-slide {
        color: #333;
        overflow: hidden;
        display: flex;
        justify-content: center;
        height: 38px;

        .service-year {
          font-size: 16px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #C5A074;
          line-height: 24px;
          padding-bottom: 20px;
        }

        .service-title {
          font-size: 48px;
          font-family: Noto Serif SC-SemiBold, Noto Serif SC;
          font-weight: 600;
          color: #434343;
          line-height: 72px;
          padding-bottom: 20px;
        }

        .service-text {
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #434343;
          line-height: 24px;
          z-index: 2;
        }

        .service-num {
          position: absolute;
          right: 0;
          bottom: -116px;
          z-index: -1;
          width: 235px;
          height: 200px;
          font-size: 200px;
          font-family: Noto Sans-Bold, Noto Sans;
          font-weight: bold;
          line-height: 200px;
          background: linear-gradient(180deg, #C5A074 0%, rgba(197, 160, 116, 0) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .swiper-slide-content {
        text-align: center;
        max-width: 1400px;
        font-size: 12px;
        z-index: 2;
        display: flex;
        align-items: center;

        .content {
          text-align: left;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          .ev-line {
            width: 32px;
            height: 4px;
            background: #BFBFBF;
            border-radius: 8px 8px 8px 8px;
          }

          .ev-year {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #bfbfbf;
            line-height: 22px;
            padding-left: 8px;
          }
        }
      }
      .content {
        text-align: left;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .ev-line {
          width: 32px;
          height: 4px;
          background: #BFBFBF;
          border-radius: 8px 8px 8px 8px;
        }

        .ev-year {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #bfbfbf;
          line-height: 22px;
          padding-left: 8px;
        }
      }
      .pla-prve {
        width: 34px;
        height: 34px;
        background: url("https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/aboutUs/upArrow.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }

      .year-weaper {
        width: 120px;
        height: 250px;
        margin: 24px 0;
        display: flex;
        align-items: center;
        .year-carousel{
          width: 120px;
        }
        .years {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #262626;
          line-height: 22px;
        }

        .years-line {
          width: 32px;
          height: 4px;
          background: #BFBFBF;
          border-radius: 8px 8px 8px 8px;
        }
      }

      .pla-next {
        width: 34px;
        height: 34px;
        background: url("https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/aboutUs/downArrow.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
    }

    .year-bold {
      font-size: 148px;
      font-family: DINPro-Bold, DINPro;
      font-weight: bold;
      color: #262626;
      line-height: 148px;
      padding-bottom: 24px;

      .year-line {
        display: inline-block;
        width: 12px;
        height: 120px;
        background-color: #c5a074;
        margin-right: 24px;
      }
    }

    .event-detail {
      width: 689px;
      height: 79px;
      overflow: hidden;
      font-size: 24px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #434343;
      line-height: 36px;
      padding-bottom: 24px;
    }
  }
}
.imgBox img:nth-child(odd){margin-right: 30px;}
</style>
<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}


</style>
