<template>
  <div class="infor">
    <div style="width: 100%; height: 96px; background: #fff"></div>
    <div class="inforRouter">
      <div class="container">
        <a style="cursor: pointer;" @click.prevent="goHome" href="https://www.wonmore.com/">首页</a>
        <span style="margin: 0 8px">></span>
        <a style="cursor: pointer;" @click.prevent="goPage" :href="secondHref">{{ second }}</a>
        <span> >{{ newsMsg.title }}</span>
      </div>
    </div>
    <div class="inforMsg">
      <!-- leftInfor -->
      <div class="leftInfor">
        <h1 class="title">{{ newsMsg.title }}</h1>
        <h3 class="littletitle">
          <span>{{ newsMsg.created_at }}</span> &nbsp;&nbsp;
          <span>
            <svg
              style="width: 16px; vertical-align: middle; margin: -1px 6px 0 8px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1024 1024"
              data-v-394d1fd8=""
            >
              <path fill="currentColor" d="M512 160c320 0 512 352 512 352S832 864 512 864 0 512 0 512s192-352 512-352zm0 64c-225.28 0-384.128 208.064-436.8 288 52.608 79.872 211.456 288 436.8 288 225.28 0 384.128-208.064 436.8-288-52.608-79.872-211.456-288-436.8-288zm0 64a224 224 0 1 1 0 448 224 224 0 0 1 0-448zm0 64a160.192 160.192 0 0 0-160 160c0 88.192 71.744 160 160 160s160-71.808 160-160-71.744-160-160-160z"></path>
            </svg>阅读{{ newsMsg.views }}</span>
        </h3>
        <div class="content" v-html="newsMsg.content"></div>
        <div class="next" v-if="newsMsg.last">
          <a class="nextText" v-if="newsMsg.last.id" @click.prevent="goMsg(newsMsg.last.id)" :title="newsMsg.last ? newsMsg.last.title : ''"
             :href="'https://www.wonmore.com/wordMsg/information?id='+newsMsg.last.id">
            <span class="line-cut1">上一篇：{{ newsMsg.last ? newsMsg.last.title : '' }}</span> >
          </a>
          <a class="nextText" v-if="newsMsg && newsMsg.next.id" @click.prevent="goMsg(newsMsg.next.id)" :title="newsMsg.next ? newsMsg.next.title : ''"
             :href="'https://www.wonmore.com/wordMsg/information?id='+newsMsg.next.id">
            <span class="line-cut1">下一篇：{{ newsMsg.next ? newsMsg.next.title : '' }}</span> >
          </a>
        </div>
      </div>
      <!-- rightInfor -->
      <div class="rightInfor">
        <div class="relevantRead">相关阅读</div>
        <div class="relevant">
          <a
              class="list"
              v-for="(item, index) in withMsg"
              :key="index"
              @click.prevent="goMsg(item.id)"
              :href="'https://www.wonmore.com/wordMsg/information?id='+item.id"
          >
            <div class="listImg">
              <img :src="item.thumbnail" alt=""/>
            </div>
            <div class="listTitle">
              <div class="line-cut2">{{ item.title }}</div>
              <div class="listTime">
                <span>阅读{{ item.views }}</span
                ><span class="margin-left:16px">{{ item.created_at.split(' ')[0] }}</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {View} from '@element-plus/icons'
import {getNewsDetail, recommend} from '@/api/api.js'
import $ from 'jquery'

export default {
  components: {
    View
  },
  data() {
    return {
      pageSize: 6,
      total: 18,
      newsMsg: {},
      withMsg: [],
      second: '',
      secondHref: '',
      routerName: ''
    }
  },
  watch: {
    $route() {
      this.getNewsDetail(this.$route.query.id)
      // if(this.$route.name='')
      // console.log(this.$route, '124567890')
      // this.userId= this.$route.query.id
    }
  },
  beforeRouteEnter: (to, from, next) => {
    next((vm) => {
      console.log(from, to, 'fromfrom')
      if(from.meta.title){
        localStorage.setItem('second',from.meta.title.substring(0,4))
        localStorage.setItem('routerName',from.name)
      }

      // vm.second = from.meta.title
      // vm.routerName = from.name;
      // console.log(vm.routerName);
    })
  },
  // beforeRouteEnter  (to, from, next){
  //   // console.log(to,'rooooooo')
  //   // 在渲染该组件的对应路由被 confirm 前调用
  //   // 不！能！获取组件实例 `this`
  //   // 因为当守卫执行前，组件实例还没被创建
  // },
  mounted() {
    // console.log(this.$route, '12456789')
    this.getNewsDetail(this.$route.query.id)
    this.recommend(this.$route.query.id)
    this.second = localStorage.getItem('second')
    this.routerName = localStorage.getItem('routerName')
    this.getSecondHref()
  },
  methods: {
    getSecondHref(){
      console.log(this.routerName)
      switch (this.routerName) {
        case "platform":
          this.secondHref = 'https://www.wonmore.com/wordMsg/platform';
        break;
        case "industryNews":
          this.secondHref = 'https://www.wonmore.com/wordMsg/industryNews';
        break;
        case "policyProvisions":
          this.secondHref = 'https://www.wonmore.com/wordMsg/policyProvisions';
        break;
        case "publicWelfares":
          this.secondHref = 'https://www.wonmore.com/socialResponsibility/publicWelfare';
        break;
        case "helpAgriculture":
          this.secondHref = 'https://www.wonmore.com/socialResponsibility/helpAgriculture';
        break;
        default:
        break
      }
    },
    goMsg(id) {
      this.$router.push({
        name: 'information',
        query: {id: id}
      })
    },
    getNewsDetail(value) {
      let data = new FormData()
      data.append('id', value)
      let that = this
      $.ajax({
        url: 'https://outcommon.wonmore.com/trade/news_detail',
        dataType: 'json',
        type: 'post',
        async: false,
        data: data,
        processData: false, // 使数据不做处理
        contentType: false, // 不要设置Content-Type请求头
        success: function (res) {
          // console.log(res)
          if (res.status === 'success') {
            res.data.created_at = res.data.created_at ? res.data.created_at.split(" ")[0] : res.data.created_at;
            that.newsMsg = res.data
            // that.resetMetas(res.data.title, res.data.keywords, res.data.description)
            that.resetMetas(res.data.title)
          }
        }
      })
    },
    resetMetas (title, keywords = '', description = '') {
      if (title) {
        document.title = title
      } else {
        document.title = '央联万贸'
      }

      let head = document.getElementsByTagName('head');

      let _keywords = document.querySelector('meta[name="keywords"]')
      if (_keywords) {
        _keywords.content = keywords
      } else {
        let meta = document.createElement('meta');
        meta.name = 'keywords'
        meta.content = keywords
        head[0].appendChild(meta)
      }

      let _description = document.querySelector('meta[name="description"]')
      if (_description) {
        _description.content = description
      } else {
        let meta = document.createElement('meta');
        meta.name = 'description'
        meta.content = description
        head[0].appendChild(meta)
      }
    },
    recommend(value) {
      let params = {id: value}
      let that = this
      $.ajax({
        url: 'https://outcommon.wonmore.com/trade/news_recommend',
        dataType: 'json',
        type: 'post',
        async: false,
        data: JSON.stringify(params),
        processData: false, // 使数据不做处理
        contentType: false, // 不要设置Content-Type请求头
        success: function (res) {
          // console.log(res)
          if (res.status === 'success') {
            that.withMsg = res.data
            // that.newsMsg = res.data
          } else {
          }
        }
      })
      // recommend({ id: value }).then((res) => {
      //   if (res.status === 'success') {
      //     console.log(res, 'resssssssssssssssss124')
      //   }
      // })
    },
    goPage(){
      // console.log(this.routerName);
      this.$router.push({
        name: this.routerName
      })
    },
    goHome(){
      this.$router.push({ name: 'homePage' })
    }
  }
}
</script>
<style lang="scss" scoped>

.infor {
  background: #fff;
  overflow: hidden;

  .inforRouter {
    height: 72px;
    // height: 24px;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #434343;
    line-height: 72px;
    background: #fafafa;
    .container{
      max-width: 1160px;
      margin: 0 auto;
    }
  }

  .inforMsg {
    display: flex;
    justify-content: center;
    padding-top: 80px;

    .leftInfor {
      width: 854px;
      margin-bottom: 80px;

      .title {
        text-align: center;
        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #434343;
        line-height: 36px;
      }

      .littletitle {
        margin: 12px auto 24px;
        text-align: center;
        vertical-align: middle;
        height: 24px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #595959;
        line-height: 24px;
      }

      .content {
        line-height: 1.4;
        border-top: 1px solid #f5f5f5f5;
        padding: 24px 10px 0;
        overflow: hidden;
        .p {
          margin-bottom: 24px !important;
        }
        span{
          width: 100%;
          display: inline;
        }
        img{
          max-width: 100%;
          display: inline-block;
        }
      }

      .next {
        border-top: 1px solid #f5f5f5;
        padding-top: 30px;
        padding-left: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .nextText {
          width: 350px;
          cursor: pointer;
          display: inline-block;
          margin-right: 0;
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #595959;
          line-height: 24px;
          span{
            max-width: calc(100% - 30px);
            vertical-align: middle;
            display: inline-block;
          }
          &:first-child{text-align: start;}
          &:last-child{text-align: end;}
        }

        .nextText:hover {
          color: red;
        }
      }
    }

    .rightInfor {
      width: 282px;
      margin-left: 64px;

      .relevantRead {
        height: 30px;
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #434343;
        line-height: 30px;
        margin-bottom: 20px;
        margin-top: 46px;
      }

      .relevant {
        padding: 20px 0;
        border: solid #f5f5f5;
        border-width: 1px 0;
        .list {
          cursor: pointer;
          display: flex;
          &:not(:last-child){
            margin-bottom: 20px;
          }
          .listImg {
            flex: 2.5;
            width: 94px;
            height: 72px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .listTitle {
            flex: 7.5;
            padding-left: 10px;

            .line-cut2 {
              height: 44px;
              font-size: 16px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #595959;
              line-height: 22px;
              margin-bottom: 6px;
            }

            span {
              height: 22px;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #bfbfbf;
              line-height: 22px;
            }
            .listTime{
              padding-right: 13px;
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}
</style>
<style scoped>
/deep/ .el-menu--horizontal>.el-menu-item{color: #000;}
</style>
