<template>
  <div class="weapers">
    <div class="container">
      <div class="service">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" data-year="01">
              <div class="swiper-slide-content">
                <div class="content">
                  <span class="service-year">CUSTOMS INSPECTION SERVICE CENTER</span>
                  <h4 class="service-title">巴龙海关查验通关中心</h4>
                  <p class="service-text">
                    已投入运营。是港口经济的重要功能设施，是目前国内首家关企全面深度合作、国内唯一的标杆性示范项目，实现了区、关、港、企全面深度合作、高效联动，提高查验通关速度75%以上，带动青岛港冻品进口由2018年的70万吨（货值约140亿元），提升到今年有望实现220万吨（货值约440亿元），进口生活资料占比由8.9%将提高到28%。目前，该中心承担了青岛港海关查验、通关、消杀总量的70%以上，避免后疫情时代物传人、人传物，确保冷链食品安全和品质。
                  </p>
                  <span class="service-num">01</span>
                </div>
                <div style="position: relative;">
                  <img src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/aboutUs/customs.png"
                     class="service-img" alt="">
                  <div class="bg-img">
                    <div class="first-div"></div>
                    <div class="second-div"></div>
                  </div>
                </div>

              </div>
            </div>
            <div class="swiper-slide" data-year="02">
              <div class="swiper-slide-content">
                <div class="content">
                  <span class="service-year">CUSTOMS INSPECTION SERVICE CENTER</span>
                  <h4 class="service-title">巴龙全品类中央保税食品世界加工中心</h4>
                  <p class="service-text">
                    2020年7月开工，2021年年底分期投入运营。建成后，是目前国内规模最大、装备技术最先进、保税区内食品全品类、全温区、全功能、全追溯、一站式集成化世界加工厂，打通日韩及东北亚保税加工业务，填补了国内港口保税加工的历史空白。实现食品全品类保税加工、分拣包装、贴牌灌装一站式集成化服务。改变过去二次物流、搬运、仓储、加工，可为食品进出口贸易企业降本增效20%—30%，实现食品产业“保税、保真、保质、保价、保鲜、保供”。为国内逐年上升的肉类进口需求提供优质保障。2018年全国肉类进口总量400万吨、2019年600万吨，2020年1000万吨。
                    经专家测算，可实现产能约2000亿，创造税收约200亿。
                  </p>
                  <span class="service-num" style="bottom: -82px;">02</span>
                </div>

                <div style="position: relative;">
                  <img src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/aboutUs/centerFee.png"
                     class="service-img" alt="">
                  <div class="bg-img">
                    <div class="first-div"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide" data-year="03">
              <div class="swiper-slide-content">
                <div class="content">
                  <span class="service-year">CUSTOMS INSPECTION SERVICE CENTER</span>
                  <h4 class="service-title">巴龙国际冷链食品交易中心</h4>
                  <p class="service-text">
                    已投入运营。面积约3万平方米，打造上海进博会的“专业食品馆”和“上合食品城”，形成食品进出口贸易总部基地。为国内外生产商、贸易商提供信息发布、产品展示、交易结算服务，实现现货、期货的线上线下交易、交割、拍卖。为海外生产商、供应商走进中国提供绿色通道，为国内进口商提供联合透明优酷网，提高国际议价能力，保障优质优价。
                  </p>
                  <span class="service-num">03</span>
                </div>
                <img
                    src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/aboutUs/internationlTrade.png"
                    class="service-img" alt="">
              </div>
            </div>

          </div>
          <div class="swiper-scrollbar"></div>
          <div class="num-position">
            <img src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/aboutUs/01.png" width="20"
                 height="23" alt="">
            <img src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/aboutUs/02.png" width="20"
                 height="23" alt="">
            <img src="https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/aboutUs/03.png" width="20"
                 height="23" alt="">
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'

export default {

  name: "serviceCenter",
  data() {
    return {
      currentIndex: 0,
    }
  },
  mounted() {
    this.myswiper()
  },
  methods: {
    myswiper: function () {
      let that = this;
      var timelineSwiper = new Swiper('.service .swiper-container', {
        direction: 'vertical',
        loop: false,
        speed: 1600,
        mousewheelControl: true,
        pagination: 'null',
        effect: 'fade',
        fade: {
          crossFade: true,
        },
        paginationBulletRender: function (swiper, index, className) {
          // console.log(index)
          // var year = document.querySelectorAll('.swiper-slide')[index].getAttribute('data-year');
          // return '<span class="' + className + '">' + year + '</span>';
        },
        paginationClickable: true,
        scrollbar: '.swiper-scrollbar',
        scrollbarHide: false,
        scrollbarDraggable: true,
        scrollbarSnapOnRelease: true,
        onSlideChangeStart: function (swiper) {
          that.currentIndex = swiper.activeIndex;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>

.weapers {
  padding: 108px 0;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    flex-direction: column;

    .service {
      width: 100%;
      background-color: #fff;

      .swiper-container {
        height: 527px;
        width: 1253px;
        overflow: hidden;
        position: relative;

        .num-position {
          width: 20px;
          height: 149px;
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          right: 66px;
          top: 166px;
          z-index: 3;
        }
      }

      .swiper-wrapper {
        transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
        height: 507px;
        z-index: 3;
      }

      .swiper-slide {
        color: #333;
        overflow: hidden;
        display: flex;
        justify-content: center;
        height: 507px !important;
        margin-bottom: 20px;

        .service-year {
          font-size: 16px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #C5A074;
          line-height: 24px;
          padding-bottom: 20px;
        }

        .service-title {
          font-size: 48px;
          font-family: Noto Serif SC-SemiBold, Noto Serif SC;
          font-weight: 600;
          color: #434343;
          line-height: 72px;
          padding-bottom: 20px;
        }

        .service-text {
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #434343;
          line-height: 24px;
          z-index: 2;
          text-align: justify;
        }

        .service-num {
          position: absolute;
          right: 0;
          bottom: -116px;
          z-index: -1;
          width: 235px;
          height: 200px;
          font-size: 200px;
          font-family: Noto Sans-Bold, Noto Sans;
          font-weight: bold;
          line-height: 200px;
          background: linear-gradient(180deg, #C5A074 0%, rgba(197, 160, 116, 0) 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          opacity: 0.5;
        }
      }

      .swiper-slide-content {
        text-align: center;
        max-width: 1400px;
        font-size: 12px;
        z-index: 2;
        display: flex;
        align-items: center;

        .content {
          width: 580px;
          text-align: left;
          margin-right: 72px;
          position: relative;
        }

        .bg-img {
          position: absolute;
          width: 500px;
          bottom: 4px;
          left: 60px;

          .first-div {
            width: 100%;
            height: 10px;
            background: #BFBFBF;
            position: absolute;
            bottom: -10px;
            right: 10px;
            z-index: 2;
          }

          .second-div {
            width: 93%;
            height: 20px;
            background: #D9D9D9;
            position: absolute;
            bottom: -20px;
            right: 26px;
            z-index: 1;
          }

        }
      }
    }
  }

  .service-img {
    width: 600px;
    height: 380px;
  }

}
</style>
<style scoped>
.swiper-container-vertical > .swiper-scrollbar {
  right: 35px;
  height: 240px;
  top: 120px;
  background: rgba(255, 255, 255, 0.5);
}

/deep/ .swiper-scrollbar-drag {
  height: 80px !important;
  background: #FFFFFF !important;
}

</style>
