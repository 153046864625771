<template>
  <div class="weaper">
    <div class="banner">
      <div class="bigtitle">
        <h1>食品溯源</h1>
        <h2>FOOD TRACEBILITY</h2>

        <p class="rouetermsg">首页 > 食品溯源</p>
      </div>
    </div>
    <div class="content">
      <div class="content-pro"></div>
      <div class="safe"></div>
      <div class="content-msg">
        <div class="title">
          <span class="gun"></span><span class="text">FOOD TRACEABILITY</span>
        </div>
        <div class="msg">
          <h1>食品溯源</h1>
          <p>
            制定食品安全标准体系，搭建食品安全屏障，打造食品安全品牌城市，形成多产融合的责任、利益、命运共同体。平台构建数字化、信息化、场景化、可视化、全追溯体系，建立“白名单”产品体系，为“八大渠道”消费安全保驾护航，实现食品安全“四个最严”，确保不发生系统性、区域性食品安全风险。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',

  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.weaper {
  .banner {
    position: relative;
    width: 100%;
    height: 404px;
    background: url('https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/foodTraceability/foodTraceabilitybg.png')
      no-repeat;
    background-size: 100% 100%;
    .bigtitle {
      position: absolute;
      left: 360px;
      bottom: 24px;
      h1 {
        width: 176px;
        height: 66px;
        font-size: 44px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 66px;
      }
      h2 {
        width: 250px;
        height: 36px;
        font-size: 24px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
      }
      .rouetermsg {
        margin-top: 96px;
        height: 24px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
    }
  }
  .content {
    width: 1200px;
    margin: 80px auto 240px;
    height: 400px;
    position: relative;


      .content-pro{
        width: 600px;
        height: 480px;
        background: url('https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/foodTraceability/foodTraceability.png')       no-repeat;
      }
    .safe{
      width: 180px;
      height: 180px;
      background: url("https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/foodTraceability/icon.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      right: 0;
      top: 0;
    }
    .content-msg {
      position: absolute;
      left: 568px;
      bottom: -96px;
      width: 632px;
      height: 480px;
      background: #f5f5f5;
      padding: 126px 32px 40px 72px;
      z-index: -1;
      .title {
        .gun {
          vertical-align: middle;
          display: inline-block;
          width: 64px;
          height: 2px;
          background: #c5a074;
          margin-right: 8px;
        }
        .text {
          vertical-align: middle;
          width: 170px;
          height: 24px;
          font-size: 16px;
          font-weight: 500;
          color: #c5a074;
          line-height: 24px;
        }
      }
      .msg {
        h1 {
          height: 72px;
          font-size: 48px;
          font-family: Noto Serif SC-SemiBold, Noto Serif SC;
          font-weight: 600;
          color: #434343;
          line-height: 72px;
          margin: 16px 0;
        }
        p {
          height: 48px;
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #434343;
          line-height: 24px;
        }
      }
    }
  }
  // .nav {
  //   width: 100%;
  //   height: 72px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   background: #FAFAFA;

  //   .nav-btn {
  //     font-size: 24px;
  //     font-family: PingFang SC-Regular, PingFang SC;
  //     font-weight: 400;
  //     color: #434343;
  //     line-height: 36px;
  //     padding: 18px 48px;
  //     margin: 0 20px;
  //     cursor: pointer;
  //     white-space: nowrap;
  //   }
  // }

  // .btn-active {
  //   color: #C5A074 !important;
  //   border-bottom: 3px solid #C5A074;
  // }
}
</style>
