<template>
  <div class="weaper">
    <div class="banner">
      <div class="ban-title">全球资讯</div>
      <div class="ban-en">GLOBAL NEWS</div>
    </div>
    <div class="nav">
      <div class="nav-btn"
           v-for="item in navList"
           :class="item.id===index?'btn-active':''"
           :key="item.id"
           @click="changeNav(item)"
      >{{ item.name }}
      </div>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import platform from '@/views/wordMsg/platform'
import industryNews from '@/views/wordMsg/industryNews'
import policyProvisions from '@/views/wordMsg/policyProvisions'

export default {
  name: "index",
  components: {
    platform,
    industryNews,
    policyProvisions
  },
  data() {
    return {
      index: 0,
      navList: [
        {
          id: 0,
          name: '平台资讯',
          url: '/wordMsg/platform'
        },
        {
          id: 1,
          name: '行业新闻',
          url: '/wordMsg/industryNews'
        },
        {
          id: 2,
          name: '政策规定',
          url: '/wordMsg/policyProvisions'
        }
      ]
    }
  },
    watch: {
    $route() {
      this.index = this.navList.findIndex((item)=> item.url ===this.$route.path)
    }
  },
  mounted() {
    this.index = this.navList.findIndex((item)=> item.url ===this.$route.path)
  },
  methods: {
    changeNav(item) {
      this.index = item.id
      this.$router.push(item.url)
    },
  }
}
</script>

<style lang="scss" scoped>
.weaper {
  .banner {
    width: 100%;
    height: 500px;
    background: url("https://outcommon.oss-cn-zhangjiakou.aliyuncs.com/trade/static/assets/wordMsg/bg(34).png") no-repeat;
    background-size: 100% 100%;
    padding-left: 360px;
    padding-top: 244px;
    .ban-title{
      font-size: 44px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 66px;
    }
    .ban-en{
      font-size: 24px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 36px;
    }
  }

  .nav {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FAFAFA;

    .nav-btn {
      font-size: 24px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #434343;
      line-height: 36px;
      padding: 18px 48px;
      margin: 0 20px;
      cursor: pointer;
      white-space: nowrap;
    }
  }

  .btn-active {
    color: #C5A074 !important;
    border-bottom: 3px solid #C5A074;
  }
}
</style>
